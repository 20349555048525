import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from '../components/BaseLayout/BaseLayout';
import Card from '../components/Card/Card';

function Cursos(props) {
  const { title } = props.data.wordpressPage;
  const cursos = props.data.allWordpressPost.edges;
  const pages = props.data.allWordpressPage.edges;

  return (
    <BaseLayout headerNav={pages} page={title}>
      {cursos.map(({ node }) => (
        <Card
          key={node.id}
          title={node.title}
          image={node.jetpack_featured_media_url}
          excerpt={node.excerpt}
          content={node.content}
        />
      ))}
    </BaseLayout>
  );
}

export const query = graphql`
  query CursosQuery {
    allWordpressPost(
      filter: {
        slug: {}
        categories: { elemMatch: { slug: { eq: "cursos" } } }
      }
    ) {
      edges {
        node {
          id
          slug
          content
          title
          excerpt
          jetpack_featured_media_url
        }
      }
    }
    wordpressPage(slug: { eq: "cursos" }) {
      id
      excerpt
      title
    }
    allWordpressPage(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          path
          id
        }
      }
    }
  }
`;

export default Cursos;
